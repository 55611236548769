import React, { Component } from "react"
import { connect } from "react-redux"
import Button from "../../components/base/Button"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import Input from "../../components/base/Input"
import SEO from "../../components/base/seo"
import MockUp from "../../images/home_mockup.png"
import {
  resetPasswordWithOob,
  verifyEmailLink,
  verifyOobCode,
} from "../../store/actions/authActions"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"

enum VerificationType {
  EMAIL = "email",
  PASSWORD = "password",
  UNKNOWN = "unknown",
}

interface State {
  verificationType: VerificationType | ""
  oobCode: string
  email: string
  password: string
  passwordError: string
}

interface Props {
  location: any
  utilsStore: utilsStateInterface
}

class VerificationPage extends Component<Props> {
  state: State = {
    email: "",
    password: "",
    passwordError: "",
    verificationType: "",
    oobCode: "",
  }

  componentDidMount() {
    const mode = this.handleGetParameterByName("mode")
    const oobCode = this.handleGetParameterByName("oobCode")
    let selectedMode: VerificationType | "" = ""
    if (mode === "resetPassword") {
      selectedMode = VerificationType.PASSWORD
    } else if (mode === "verifyEmail") {
      selectedMode = VerificationType.EMAIL
    }

    if (selectedMode && oobCode) {
      this.setState(
        {
          oobCode: oobCode,
        },
        async () => {
          try {
            if (selectedMode === VerificationType.PASSWORD) {
              const email = await verifyOobCode(
                this.state.oobCode,
                this.props.utilsStore.firebase
              )
              this.setState({
                email: email,
                verificationType: VerificationType.PASSWORD,
              })
            } else if (selectedMode === VerificationType.EMAIL) {
              await verifyEmailLink(
                this.state.oobCode,
                this.props.utilsStore.firebase
              )
              this.setState({
                verificationType: VerificationType.EMAIL,
              })
            }
          } catch (err) {
            this.setState({
              verificationType: VerificationType.UNKNOWN,
            })
          }
        }
      )
    } else {
      this.setState({
        verificationType: VerificationType.UNKNOWN,
      })
    }
  }

  handleGetParameterByName(name: string) {
    //eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&")
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
    const results = regex.exec(this.props.location.search)
    if (!results) return null
    if (!results[2]) return ""
    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

  handleInputChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleResetPassword = async () => {
    if (this.state.password.replace(/\s/g, "").length < 6) {
      this.setState({
        passwordError: "Please enter at least 6 characters",
      })
    } else {
      this.setState({
        passwordError: "",
      })
      try {
        await resetPasswordWithOob(
          this.state.oobCode,
          this.state.password,
          this.props.utilsStore.firebase
        )
        this.setState({
          passwordError: "Your password has been successfully reset",
        })
      } catch (err) {
        this.setState({
          passwordError: err.message,
        })
      }
    }
  }

  renderVerificationAttributes = () => {
    let contentText = ""
    let contentDescription = ""

    switch (this.state.verificationType) {
      case VerificationType.EMAIL:
        contentText = "Email Verification"
        contentDescription = "Your email has been succesfully verified"
        break
      case VerificationType.PASSWORD:
        contentText = "Reset Your Password"
        if (this.state.email) {
          contentDescription = `Reset your password for ${this.state.email}`
        }
        break
      case VerificationType.UNKNOWN:
        contentText = "Code Error"
        contentDescription =
          "The desired action is invalid, it may be due to this link has expired"
        break
    }

    return (
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-14">
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  {contentText}
                </h1>
                {contentDescription && (
                  <p className="text-sm mt-3 text-gray-600 sm:text-base">
                    {contentDescription}
                  </p>
                )}
                {this.state.verificationType === VerificationType.PASSWORD && (
                  <form
                    action="#"
                    className="mt-12 sm:max-w-lg sm:w-full sm:flex"
                  >
                    <div className="min-w-0 flex-1">
                      <Input
                        id="password"
                        value={this.state.password}
                        error={this.state.passwordError}
                        placeholder="Enter your new password"
                        label="New password"
                        type="password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-3">
                      <Button
                        className="w-full"
                        text="Reset Password"
                        type="normal"
                        onClick={this.handleResetPassword}
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.renderSvg()}
      </div>
    )
  }

  renderSvg = () => {
    return (
      <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="hidden sm:block">
            <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
            <svg
              className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
              width="404"
              height="392"
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="392"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </svg>
          </div>
          <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
            <img
              className="w-8/12 rounded-md lg:h-full lg:w-auto lg:max-w-none"
              src={MockUp}
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="bg-white">
        <SEO
          title="Arus Oil - Auth Verification"
          description="Verify your account in Arus Oil now. This allows you to view daily purchase rates of used cooking oil,contact logistic for collection and view payment made to you."
        />
        <Header page="Verification" />
        {this.renderVerificationAttributes()}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(VerificationPage)
